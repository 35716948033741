import { Button, Center, Icon, StackDivider, VStack } from '@chakra-ui/react';

import { memo } from 'react';

import dynamic from 'next/dynamic';
import { getAcfBlockFetchData } from 'utls';
import { useTranslation, useWpInfiniteItems } from 'hooks';
import { ItemSkeleton } from 'components/shared/Skeleton';
import { ArrowLeft } from 'iconsax-react';

const PostItem = dynamic(() => import('../../shared/PostItem'));

const WideCards = props => {
  const { t } = useTranslation();
  const { attrs, initialData, blockName } = props;

  const posts = initialData?.items;

  if (posts?.length === 0) {
    return null;
  }

  const { data } = attrs;
  const { name, params } = getAcfBlockFetchData({
    blockName,
    ...data
  });

  const { items, hasMore, isLoading, loadMore } = useWpInfiniteItems(
    name,
    params
  );

  if (items?.length === 0) return null;

  const { enable_pagination } = data;

  const hasLoadMore = hasMore && enable_pagination === '1';

  return (
    <>
      <VStack
        w='full'
        align={'stretch'}
        spacing={4}
        divider={<StackDivider borderColor='gray.200' />}
        borderBottomWidth={1}
        borderBottomColor='gray.200'
        py='4'
      >
        {Array.isArray(items) &&
          items?.length > 0 &&
          items?.map(item => <PostItem key={item?.id} {...item} />)}

        {isLoading &&
          Array(10)
            .fill(0)
            .map((_, i) => <ItemSkeleton key={i} />)}
      </VStack>
      {hasLoadMore && (
        <Center>
          <Button
            w='full'
            rightIcon={<Icon as={ArrowLeft} />}
            onClick={loadMore}
            isLoading={isLoading}
            isDisabled={isLoading}
            color='#292D32'
            bg='#F6F7F7'
          >
            {t('general.more')}
          </Button>
        </Center>
      )}
    </>
  );
};

export default memo(WideCards);
